import "./App.css";
import { BrowserRouter, Route, Routes, useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Main from "./components/layouts/Main";
import SingIn from "./pages/auth/SignIn";
import AuthMain from "./components/layouts/AuthMain";
import SignUp from "./pages/auth/SignUp";
import AuthState from "./context/auth/AuthState";
import Esi from "./pages/esi/Esi";
import AddEsi from "./pages/esi/AddEsi";
import EditEsi from "./pages/esi/EditEsi";
import PfList from "./pages/pf/PfList";
import AddPf from "./pages/pf/AddPf";
import EditPf from "./pages/pf/EditPf";
import AddHoliday from "./pages/hrms/holiday/AddHoliday";
import Holiday from "./pages/hrms/holiday/holiday";
import PunchKey from "./pages/hrms/punchKeyManager/PunchKey";
import AddPunchKey from "./pages/hrms/punchKeyManager/AddPunchKey";
import AddRoles from "./pages/Roles/AddRoles";
import Roles from "./pages/Roles/Roles";
import RolePermission from "./pages/Roles/RolePermission";
import CompanyForm from "./pages/hrms/companyForm/companyForm";
import AddCompanyForm from "./pages/hrms/companyForm/AddCompanyForm";
import EditCompanyForm from './pages/hrms/companyForm/EditCompanyForm'
import Attendance from "./pages/hrms/attendance/Attendance";
import Payroll from "./pages/hrms/payroll/payroll";
import UserList from "./pages/hrms/userForm/UserList";
import AddUser from "./pages/hrms/userForm/AddUser";
import LeaveList from "./pages/hrms/Leave/LeaveList";
import LeaveForm from "./pages/hrms/Leave/LeaveForm";
import AttendanceList from "./pages/hrms/attendance/AttendanceLIst";
import Regularization from "./pages/hrms/Regularization/Regularization";
import ContractorList from "./pages/hrms/contractors/ContractorList";
import AddContractor from "./pages/hrms/contractors/AddContractors";

function App() {
  return (
    <>
    <ToastContainer/>
    <BrowserRouter>
      <AuthState>
          <Routes>
            {/* Routes for login start */}
            <Route path="/" element={<AuthMain />}>
              <Route path="auth/signin" element={<SingIn />} />
              <Route path="auth/signup" element={<SignUp />} />
            </Route>
            {/* Routes for login start */}
            {/* Routes for auth user start */}
            <Route path="/admin" element={<Main />}>

              <Route path="user" element={<UserList />} />
              <Route path="user/add" element={<AddUser />} />
              <Route path="user/edit/:id" element={<AddUser />} />

              <Route path="contractor" element={<ContractorList />} />
              <Route path="contractor/add" element={<AddContractor />} />
              <Route path="contractor/edit/:id" element={<AddContractor />} />

              <Route path="companyform" element={<CompanyForm />} />
              <Route path="companyform/add" element={<AddCompanyForm />} />
              <Route path="company/edit/:id" element={<EditCompanyForm />} />

              <Route path="leave" element={<LeaveList />} />
              <Route path="leave-form/add" element={<LeaveForm/>} />

              <Route path="holiday" element={<Holiday />} />
              <Route path="holiday/add" element={<AddHoliday />} />
              <Route path="holiday/edit/:id" element={<AddHoliday />} />

              <Route path="punchKeyManager" element={<PunchKey/>} />
              <Route path="punchKeyManager/add" element={<AddPunchKey />} />
              <Route path="punchKeyManager/edit/:id" element={<AddPunchKey />} />

              <Route path="esi" element={<Esi />} />
              <Route path="esi/add" element={<AddEsi />} />
              <Route path="esi/edit/:id" element={<EditEsi />} />
              {/* Routes for Pf */}
              <Route path="pf" element={<PfList />} />
              <Route path="pf/add" element={<AddPf />} />
              <Route path="pf/edit/:id" element={<EditPf />} />

              <Route path="attendance" element={<AttendanceList/>} />
              <Route path="add-attendance" element={<Attendance />} />

              <Route path="regularization" element={<Regularization />} />
             
              <Route path="payroll" element={<Payroll />} />

              <Route path="roles" element={<Roles />} />
              <Route path="roles/add" element={<AddRoles />} />
              <Route path="roles/permissions/:roleId" element={<RolePermission />} />
            </Route>

            {/* Routes for auth user end */}
          </Routes>
      </AuthState>
    </BrowserRouter>
    </>
  );
}

export default App;
