import { useContext, useEffect, useState } from 'react';
import { BiEdit } from 'react-icons/bi';
import { FaGreaterThan, FaLessThan } from 'react-icons/fa';
import { RiDeleteBinLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { showErrorToast, showSuccessToast } from '../../../Toaster';
import DataTable2 from '../../../context/components/DataTable2/index';
import axiosPrivate from '../../../utils/http/axiosPrivate';
import AuthContext from '../../../context/auth/AuthContext';

const PunchKey = ({ authPermission }) => {
	const { checkPermissionExists, authPermissions } = useContext(AuthContext);
	const [punchData, setPunchData] = useState([]);

	const headers = [
		{ name: ' PUNCH IN KEY', field: 'punch_in_key', sortable: true, classKey: '' },
		{ name: 'PUNCH OUT KEY', field: 'punch_out_key', sortable: true, classKey: '' },
		{ name: 'LOCATION', field: 'location', sortable: true, classKey: '' },
		{ name: 'ACTION', field: 'action', classKey: '' },
	];

	const searchItems = ['location'];
	// get All nmStaff list
	useEffect(() => {
		axiosPrivate
			.get('punch-key-manager')
			.then((punch) => {
				const punchingData = punch?.data?.data?.map((value, index) => {
					let buttons = [];
					// if (true && authPermissions.includes('Staff-Edit'))
					buttons.push(
						<Link
							key="editButton##1"
							type="button"
							to={`/admin/punchKeyManager/edit/${value.id}`}
							className="btn btn-icon"
							title="Edit"
						>
							<BiEdit style={{ color: 'green' }} size={18} />
						</Link>
					);
					// if (true && authPermissions.includes(`Staff-Delete`))
					buttons.push(
						<button
							key="deleteButton##1"
							type="button"
							data-id={value.id}
							onClick={() => punchDeleteHandler(value.id)}
							className="btn btn-icon js-sweetalert"
							title="Delete"
						>
							<RiDeleteBinLine style={{ color: 'red' }} size={17} />
						</button>
					);
					value['sr_no'] = index + 1;
					value['action'] = buttons.length > 0 ? buttons : '-';
					return value;
				});

				setPunchData(punchingData);
			})
			.catch((error) => {
				if (error.response) {
					//response status is an error code
					showErrorToast('Internal server error');
				}
			});
	}, []);

	//alert message
	

	// delete  user function
	const punchDeleteHandler = async (id) => {
		axiosPrivate
			.delete(`punch-key-manager/${id}`)
			.then(async (response) => {
				if (response.data.code === 201) {
					setPunchData([]);
					showSuccessToast('Punch Key Deleted Successfully!');
					await refreshTable();
				}
			})
			.catch((error) => {
				if (error.response) {
					//response status is an error code
					showErrorToast('Internal server error');
				}
			});
	};

	//after delete.... refresh nmStaff list
	const refreshTable = () => {
		return new Promise((resolve, reject) => {
			const options = {
				headers: { 'content-type': 'application/json' },
			};
			axiosPrivate
				.get('punch-key-manager')
				.then((punch) => {
					const punchingData = punch?.data?.data?.map((value, index) => {
						let buttons = [];
						// if (true && authPermissions.includes('Staff-Edit'))
						buttons.push(
							<Link
								key="editButton##1"
								type="button"
								to={`/admin/punchKeyManager/edit/${value.id}`}
								className="btn btn-icon"
								title="Edit"
							>
								<BiEdit style={{ color: 'green' }} size={18} />
							</Link>
						);
						// if (true && authPermissions.includes(`Staff-Delete`))
						buttons.push(
							<button
								key="deleteButton##1"
								type="button"
								data-id={value.id}
								onClick={() => punchDeleteHandler(value.id)}
								className="btn btn-icon js-sweetalert"
								title="Delete"
							>
								<RiDeleteBinLine style={{ color: 'red' }} size={17} />
							</button>
						);
						value['sr_no'] = index + 1;
						value['action'] = buttons.length > 0 ? buttons : '-';
						return value;
					});

					setPunchData(punchingData);
				})
				.catch((error) => {
					if (error.response) {
						//response status is an error code
						showErrorToast('Internal server error');
					}
				});
		});
	};

	return (
		<>
			<div className="mx-5">
				<div className="d-flex justify-content-between align-items-center mt-3">
					<ul className="nav nav-tabs page-header-tab text-dark">
					<li className="nav-item navSpace">
					<div className="card-header headerSpace">
								<div className="breadcrumb-area">
									{/* header heading */}
									<h1
										className="card-title text-dark"
										style={{ fontWeight: '700', fontSize: '18px' }}
									>
										PunchKey Manager
									</h1>

									{/* header routing */}
									<ol className="headerroutes breadcrumb m-0 bg-none ">
										<li className="item color-gray">|</li>
										<li className="item mx-2">
												Dashboard
										</li>
										<li className="item">
											<FaGreaterThan style={{ fontSize: '14px' }} />
										</li>
										<li className="item mx-2 mt-1" style={{ fontSize: '14px' }}>
										PunchKey Manager
										</li>
									</ol>
								</div>
							</div>
						</li>
					</ul>

					{/* right side add button */}
					<div className="header-action">
						{/* {authPermissions.includes('Staff-Create') ? ( */}
						<Link to="/admin/punchKeyManager/add" className="text-decoration-none">
							<button
								type="button"
								className="btn btn-primary"
								data-toggle="modal"
								data-target="#exampleModal"
							>
								<i className="fe fe-plus mr-2" />
								Add
							</button>
						</Link>
						{/* ) : null} */}
					</div>
				</div>
			</div>

			<div className="section-body mt-3">
				<div className="container-fluid">
					<div className="tab-content mt-3">
						<div
							className="tab-pane fade show active"
							id="user-list"
							role="tabpanel"
						>
							<div className="card">
								<div className="card-body">
									<div className="table-responsive">
										{
											<DataTable2
												lists={punchData}
												headers={headers}
												searchItems={searchItems}
											/>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default PunchKey;
