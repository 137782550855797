import { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import moment from "moment";
import * as Yup from "yup";
import { FaGreaterThan } from "react-icons/fa";
import { Link } from "react-router-dom";
import { showErrorToast, showSuccessToast } from "../../../Toaster";
import axiosPrivate from "../../../utils/http/axiosPrivate";
import styles from "./regularizationForm.module.css";
import { Dropdown } from "primereact/dropdown";
import "primereact/resources/themes/saga-blue/theme.css"; // theme
import "primereact/resources/primereact.min.css"; // core css
import "primeicons/primeicons.css"; // icons
import { Calendar } from "primereact/calendar";

const Regularization = () => {
  const [selectedDate, setSelectedDate] = useState(false);
  const [missingDate, setMissingDate] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [activeDate, setActiveDate] = useState("");
  const [activeEmployee, setActiveEmployee] = useState("");
  const requests = [
    { name: "Regularize", code: 0 },
    { name: "Leave Request", code: 1 },
  ];
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");

  useEffect(() => {
    const options = {
      headers: { "content-type": "application/json" },
    };

    axiosPrivate
      .get("/get-missing-attendances", options)
      .then((res) => {
        setMissingDate(res?.data?.data);
      })
      .catch((error) => {
        if (error.response) {
          // showErrorToast(error?.response?.data?.message);
        }
      });
  }, []);

  const getEmployeesByDate = (date) => {
    axiosPrivate
      .post("/get-missing-attendances-by-date", { date })
      .then((res) => {
        setEmployeeList(res?.data?.data);
        window.scrollTo(100, 100);
        setSelectedDate(true);
        setEmployeeData([]);
      })
      .catch((error) => {
        if (error.response) {
          // showErrorToast(error?.response?.data?.message);
        }
      });
  };

  const employeeDetails = (id, date) => {
    
    setEmployeeData([]);
    axiosPrivate
      .post(`get-missing-attendances/empcode/${id}`, {
        date: date,
      })
      .then((res) => {
        setEmployeeData(res?.data?.data);
        window.scrollTo(100, 100);
      })
      .catch((error) => {
        if (error.response) {
          // showErrorToast(error?.response?.data?.message);
        }
      });
  };

  const handleSubmit = async (
    values,
    details,
    setSubmitting,
    setFieldError
  ) => {
    try {
      const formattedTime = moment(values.punchTime).format("HH:mm:ss"); // Format time to 24-hour format
      const res = await axiosPrivate
        .post("/regularize-attendances", {
          id: details?.id,
          type: details?.in_date ? "OUT" : "IN",
          date: details?.in_date ? details?.in_date : details?.out_date,
          time: formattedTime, // Use formatted time here
          leave: values.requestType,
        })
        .then((res) => {
          getEmployeesByDate(
            details?.in_date ? details?.in_date : details?.out_date
          );
          employeeDetails(
            details?.emp_code,
            details?.in_date ? details?.in_date : details?.out_date
          );
          showSuccessToast(res?.data?.message);
        });
    } catch (error) {
      if (error.message) {
        showErrorToast(error?.response?.data?.message);
      }
    }
    setSubmitting(false);
  };

  const EmployeeForm = ({ details }) => {
    const validationRules = {
      requestType: Yup.number().required("Request type is required"),
    };

    const formik = useFormik({
      initialValues: {
        requestType: "",
        punchTime: "", // Default to current date/time
      },
      validationSchema: Yup.object(validationRules),
      onSubmit: (values, { setSubmitting, setFieldError }) =>
        handleSubmit(values, details, setSubmitting, setFieldError),
    });

    return (
      <form onSubmit={formik.handleSubmit}>
        <div className={styles.contentList2}>
          <div className={styles.employeeContainer}>
            <div style={{ display: "flex" }}>
              <div>Punch In: &nbsp;</div>
              {details?.in_time ? (
                <div style={{ color: "#004583" }}>{details?.in_time?.slice(0, 5)}</div>
              ) : (
                <Calendar
                  timeOnly
                  showTime
                  hourFormat="24"
                  placeholder="HH:mm"
                  style={{ width: "85px", height: "25px" }}
                  value={formik.values.punchTime}
                  onChange={(e) => formik.setFieldValue("punchTime", e.value)}
                />
              )}
            </div>
            <div style={{ display: "flex" }}>
              <div>Punch Out: &nbsp;</div>
              {details?.out_time ? (
                <div style={{ color: "#004583" }}>{details?.out_time?.slice(0, 5)}</div>
              ) : (
                <Calendar
                  timeOnly
                  showTime
                  hourFormat="24"
                  placeholder="HH:mm"
                  style={{ width: "85px", height: "25px" }}
                  value={formik.values.punchTime}
                  onChange={(e) => formik.setFieldValue("punchTime", e.value)}
                />
              )}
            </div>
          </div>
          {details?.in_time && details?.out_time ? null : (
            <>
              <div
                className="card flex justify-content-center"
                style={{ marginTop: "10px" }}
              >
                <Dropdown
                  value={requests.find(
                    (r) => r.code === formik.values.requestType
                  )}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "requestType",
                      e.value ? e.value.code : ""
                    )
                  }
                  options={requests}
                  optionLabel="name"
                  showClear
                  placeholder="Select Request Type"
                  className="md:w-14rem"
                />
                {formik.touched.requestType && formik.errors.requestType ? (
                  <div className={styles.invalidDataError}>
                    {formik.errors.requestType}
                  </div>
                ) : null}
              </div>
              <div style={{ textAlign: "right" }}>
                <input
                  type="submit"
                  className="btn btn-primary"
                  value="Submit"
                />
              </div>
            </>
          )}
        </div>
      </form>
    );
  };

  const fetchRegularization = () => {
    axiosPrivate
      .get(`/get-missing-attendances?month=${month}&year=${year}`)
      .then((res) => {
        setMissingDate(res?.data?.data);
        // showSuccessToast("Attendance data fetched successfully");
      })
      .catch((error) => {
        if (error.response) {
          // showErrorToast(error?.response?.data?.message);
        }
      });
  };

  useEffect(() => {
    // Get the current year and month
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = String(currentDate.getMonth() + 1).padStart(2, "0");
    // Set the state for year and month
    setYear(currentYear);
    setMonth(currentMonth);
  }, []);


  useEffect(() =>{
    fetchRegularization();
  }, [month, year])
  return (
    <>
      <div className="mx-5">
        <div className="d-flex justify-content-between align-items-center mt-3">
          <ul className="nav nav-tabs page-header-tab text-dark">
            <li className="nav-item navSpace">
              <div className="card-header headerSpace">
                <div className="breadcrumb-area">
                  <h1
                    className="card-title text-dark"
                    style={{ fontWeight: "700", fontSize: "18px" }}
                  >
                    Regularization
                  </h1>
                  <ol className="headerroutes breadcrumb m-0 bg-none ">
                    <li className="item color-gray">|</li>
                    <li className="item mx-2">Dashboard</li>
                    <li className="item">
                      <FaGreaterThan style={{ fontSize: "14px" }} />
                    </li>
                    <li className="item mx-2 mt-1" style={{ fontSize: "14px" }}>
                      Regularization list
                    </li>
                  </ol>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div className="section-body mt-3">
        <div className="container-fluid">
          <div className="tab-content mt-3">
            <div
              className="tab-pane fade show active"
              id="user-list"
              role="tabpanel"
            >
              <div className="card">
                <div className="card-body">
                  {/* Form for Month and Year */}
                  <div className="mb-4 d-flex">
                    <input
                      type="month"
                      id="month"
                      name="month"
                      onChange={(e) => {
                        const year = e.target.value.split("-")[0];
                        const month = e.target.value.split("-")[1];
                        setYear(year);
                        setMonth(month);
                      }}
                      value={`${year}-${month}`}
                    ></input>

                    <div>
                      <button
                        className="btn btn-primary ml-2"
                        onClick={fetchRegularization}
                      >
                        Fetch Regularization
                      </button>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <div className={styles.headerContainer}>
                      <div className={styles.textContainer1}>
                        <div>Missing Date</div>
                      </div>
                      {selectedDate && (
                        <div className={styles.textContainer1}>
                          <div>Employee Name</div>
                        </div>
                      )}
                      {employeeData?.length > 0 && (
                        <div className={styles.textContainer2}>
                          <div>Regularization Details Of Employee</div>
                        </div>
                      )}
                    </div>
                    <div className={styles.contentContainer}>
                      <div
                        className={`${styles.contentText1} ${styles.resizeContent1}`}
                      >
                        {missingDate &&
                          missingDate.map((item) => (
                            <div
                              style={{
                                backgroundColor:
                                  activeDate === item?.date ? "#ececec" : "",
                              }}
                              className={styles.contentList1}
                              onClick={() => {
                                getEmployeesByDate(item?.date);
                                setActiveDate(item?.date);
                              }}
                              key={item?.date}
                            >
                              {moment(item?.date).format("DD-MM-YYYY")} (
                              {item?.count})
                            </div>
                          ))}
                      </div>
                      {selectedDate && (
                        <div className={styles.contentText1}>
                          {employeeList &&
                            employeeList.map((item) => (
                              <div
                                style={{
                                  backgroundColor:
                                    activeEmployee === item?.emp_code
                                      ? "#ececec"
                                      : "",
                                }}
                                className={styles.contentList1}
                                onClick={() => {
                                  employeeDetails(
                                    item?.emp_code,
                                    item?.in_date
                                      ? item?.in_date
                                      : item?.out_date
                                  );
                                  setActiveEmployee(item?.emp_code);
                                }}
                                key={item?.emp_code}
                              >
                                {item?.emp_name
                                  ? item?.emp_name + ` (${item?.emp_code})`
                                  : item?.emp_code}
                              </div>
                            ))}
                        </div>
                      )}
                      <div className={styles.contentText2}>
                        {employeeData?.length > 0 &&
                          employeeData.map((item) => (
                            <EmployeeForm key={item?.emp_code} details={item} />
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Regularization;
