import { FaUserCircle } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../../context/auth/AuthContext";
import { useContext } from "react";

const Header = (props) => {
  const navigate = useNavigate();

  const { setUserDetails } = useContext(AuthContext);

  const onLogOut = () => {
    localStorage.removeItem("userData");
    localStorage.removeItem("userDetails");
    localStorage.removeItem("userRefreshToken");
    setUserDetails({});
    navigate("/auth/signin");
  };

  return (
    <div>
      <div
        id="page_top"
        className="section-body "
        style={{ backgroundColor: "#004583" }}
      >
        <div className="container-fluid">
          <div className="page-header">
            <div className="left">
              {/* <div className="hright">
						<div className="dropdown">
							<span className="nav-link icon settingbar">
								<i
									className="fa fa-gear fa-spin "
									data-toggle="tooltip"
									data-placement="right"
									title="Settings"
								></i>
							</span>
							<p className="nav-link icon menu_toggle">
								<i className="fa  fa-align-left text-dark"></i>
							</p>
						</div>
					</div> */}

              {/* <div className="hright">
								<div className="dropdown">
 									<p className="nav-link icon menu_toggle m-0 ">
										<i className="fa  fa-align-left text-success"></i>
 									</p>
								</div>
							</div> */}

              {/* <div className="input-group xs-hide">
								<input
									type="text"
									className="NavSearchBar form-control-plaintext"
									placeholder="Search..."
								/>
							</div> */}
            </div>

            <div className="right">
              <div className="notification d-flex">
                {/* <div className="dropdown d-flex">
                  <div className="nav-link icon d-none d-md-flex btn btn-default btn-icon ml-1">
                    <i
                      className="fa fa-bell text-white"
                      style={{ fontSize: "21px" }}
                    ></i>
                    <span
                      className="badge badge-primary nav-unread "
                      style={{ backgroundColor: "green", color: "green" }}
                    ></span>
                  </div>
                  <a
                    href="/#"
                    className="nav-link icon d-none d-md-flex btn btn-default btn-icon ml-1"
                    data-toggle="dropdown"
                  >
                    <i
                      className="fa fa-bell text-white"
                      style={{ fontSize: "21px" }}
                    ></i>
                    <span
                      className="badge badge-primary nav-unread "
                      style={{ backgroundColor: "green", color: "green" }}
                    ></span>
                  </a>
                  <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                    <ul className="list-unstyled feeds_widget">
                      <li>
                        <div className="feeds-left">
                          <i className="fa fa-check"></i>
                        </div>
                        <div className="feeds-body">
                          <h4 className="title text-danger">
                            Issue Fixed{" "}
                            <small className="float-right text-muted">
                              11:05
                            </small>
                          </h4>
                          <small>
                            WE have fix all Design bug with Responsive
                          </small>
                        </div>
                      </li>
                      <li>
                        <div className="feeds-left">
                          <i className="fa fa-user"></i>
                        </div>
                        <div className="feeds-body">
                          <h4 className="title">
                            New User
                            <small className="float-right text-muted">
                              10:45
                            </small>
                          </h4>
                          <small>I feel great! Thanks team</small>
                        </div>
                      </li>
                      <li>
                        <div className="feeds-left">
                          <i className="fa fa-thumbs-o-up"></i>
                        </div>
                        <div className="feeds-body">
                          <h4 className="title">
                            7 New Feedback{" "}
                            <small className="float-right text-muted">
                              Today
                            </small>
                          </h4>
                          <small>
                            It will give a smart finishing to your site
                          </small>
                        </div>
                      </li>
                      <li>
                        <div className="feeds-left">
                          <i className="fa fa-question-circle"></i>
                        </div>
                        <div className="feeds-body">
                          <h4 className="title text-warning">
                            Server Warning{" "}
                            <small className="float-right text-muted">
                              10:50
                            </small>
                          </h4>
                          <small>Your connection is not private</small>
                        </div>
                      </li>
                      <li>
                        <div className="feeds-left">
                          <i className="fa fa-shopping-cart"></i>
                        </div>
                        <div className="feeds-body">
                          <h4 className="title">
                            7 New Orders{" "}
                            <small className="float-right text-muted">
                              11:35
                            </small>
                          </h4>
                          <small>You received a new oder from Tina.</small>
                        </div>
                      </li>
                    </ul>
                    <div className="dropdown-divider"></div>
                    <a
                      href="fake_url"
                      className="dropdown-item text-center text-muted-dark readall"
                    >
                      Mark all as read
                    </a>
                  </div>
                </div> */}

                {/* user Profile */}
                <div className="dropdown d-flex">
                  <a
                    href="/#"
                    className="nav-link icon d-none d-md-flex btn btn-default btn-icon ml-1"
                    data-toggle="dropdown"
                  >
                    {/* <FaUserCircle size={25} /> */}
                    {/* <i className="fa fa-user text-white" style={{ fontSize: '25px' }}></i> */}
                    <FaUserCircle
                      className="text-white"
                      style={{ fontSize: "25px" }}
                    />
                  </a>
                  <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                    {/* <Link className="dropdown-item" to="#">
                      <i
                        className="dropdown-icon fe fe-edit"
                        style={{ fontSize: "15px " }}
                      ></i>
                      Profile
                    </Link> */}

                    <a className="dropdown-item" style={{cursor:'pointer'}} onClick={() => onLogOut()}>
                      <i className="dropdown-icon fe fe-log-out"></i> Log out
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
