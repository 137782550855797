import React, { useEffect, useState } from "react";
import DataTable2 from "../../../context/components/DataTable2";
import AuthContext from "../../../context/auth/AuthContext";
import axiosPrivate from "../../../utils/http/axiosPrivate";
import { Link } from "react-router-dom/dist";
import { BiEdit } from "react-icons/bi";
import { FaGreaterThan } from "react-icons/fa";
import { Dropdown } from "primereact/dropdown";
import "primereact/resources/themes/saga-blue/theme.css"; // theme
import "primereact/resources/primereact.min.css"; // core css
import "primeicons/primeicons.css"; // icons
import { Calendar } from "primereact/calendar";
import { showErrorToast, showSuccessToast } from "../../../Toaster";
import { Toggle } from "../../../components/layouts/Toggle/Toggle";

function AttendanceList() {
  const [attendance, setAttendance] = useState([]);
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [wagesType, setWagesType] = useState("monthly");

  const headers =
    wagesType === "monthly"
      ? [
          { name: "Name", field: "name", sortable: true, classKey: "" },
          {
            name: "Wages Type",
            field: "wages_type",
            sortable: true,
            classKey: "",
          },
          {
            name: "Present Days",
            field: "present_days",
            sortable: false,
            classKey: "",
          },
          {
            name: "Absent Days",
            field: "absent_days",
            sortable: false,
            classKey: "",
          },
          {
            name: "Total Days",
            field: "total_days",
            sortable: false,
            classKey: "",
          },
        ]
      : [
          { name: "Name", field: "name", sortable: true, classKey: "" },
          {
            name: "Wages Type",
            field: "wages_type",
            sortable: true,
            classKey: "",
          },

          {
            name: "Total Hours",
            field: "present_hours",
            sortable: false,
            classKey: "",
          },
        ];

  const searchItems = ["name", "wages_type"];

  

  useEffect(() => {
    // Get the current year and month
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = String(currentDate.getMonth() + 1).padStart(2, "0");
    // Set the state for year and month
    setYear(currentYear);
    setMonth(currentMonth);
  }, []);

  const handleFetchAttendance = () => {
    axiosPrivate
      .get(`/attendance?month=${month}&year=${year}&wagesType=${wagesType}`)
      .then((res) => {
        setAttendance(res?.data?.data);
        // showSuccessToast("Attendance data fetched successfully");
      })
      .catch((error) => {
        if (error.response) {
          // showErrorToast(error?.response?.data?.message);
        }
      });
  };
  useEffect(() => {
    handleFetchAttendance();
  }, [wagesType, month, year]);
  const logState = (state) => {
    if (state) {
      setWagesType("daily");
    } else {
      setWagesType("monthly");
    }
  };
  return (
    <>
      <div className="mx-5">
        <div className="d-flex justify-content-between align-items-center mt-3">
          <ul className="nav nav-tabs page-header-tab text-dark">
            <li className="nav-item navSpace">
              <div className="card-header headerSpace">
                <strong className="top-left-heading">ATTENDANCE</strong>

                <ol className="headerroutes breadcrumb m-0 bg-none ">
                  <li className="item color-gray">|</li>
                  <li className="route-heading item mx-2">Dashboard</li>
                  <li className="item">
                    <FaGreaterThan className="grater-than-route" />
                  </li>
                  <li className="route-heading item mx-2 mt-1">Attendance</li>
                </ol>
              </div>
            </li>
          </ul>

          {/* right side add button */}
          <div className="header-action">
            {/* {authPermissions.includes("Category-Create") ? ( */}
            <Link to="/admin/add-attendance" className="text-decoration-none">
              <button
                type="button"
                className="btn btn-primary"
                data-toggle="modal"
                data-target="#exampleModal"
              >
                <i className="fe fe-plus mr-2" />
                Add
              </button>
            </Link>
            {/* ) : null} */}
          </div>
        </div>
      </div>
      <div className="section-body mt-3">
        <div className="container-fluid">
          <div className="tab-content mt-3">
            <div
              className="tab-pane fade show active"
              id="category-list"
              role="tabpanel"
            >
              <div className="card">
                <div className="card-body">
                  {/* Form for Month and Year */}

                  <div className="mb-4 d-flex justify-content-between">
                    <div className="d-flex">
                      <input
                        type="month"
                        id="month"
                        name="month"
                        onChange={(e) => {
                          const year = e.target.value.split("-")[0];
                          const month = e.target.value.split("-")[1];
                          setYear(year);
                          setMonth(month);
                        }}
                        value={`${year}-${month}`}
                      ></input>

                      <div>
                        <button
                          className="btn btn-primary ml-2"
                          onClick={handleFetchAttendance}
                        >
                          Fetch Attendances
                        </button>
                      </div>
                    </div>

                    <div>
                      <span>Monthly</span>&nbsp;
                      <Toggle
                        // label="Toggle me"
                        toggled={false}
                        onClick={logState}
                      />
                      &nbsp;
                      <span>Daily</span>
                    </div>
                  </div>
                  <div className="table-responsive">
                    {
                      <DataTable2
                        lists={attendance}
                        headers={headers}
                        searchItems={searchItems}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AttendanceList;
